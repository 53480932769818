<template>
  <b-card
    no-body
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        {{ $t('cards.affiliateView.affiliate_links') }}
      </h5>
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled my-1">
        <li>
          <label>
            {{ $t('cards.affiliateView.sales_link') }}
          </label>
          <b-input-group>
            <b-form-input
              :value="itemData.sales_link"
              :readonly="true"
            />

            <b-input-group-append>
              <b-button
                variant="outline-primary"
                @click="copyAction('sales_link')"
                @mouseleave="isCopiedFlags.sales_link = false"
              >
                <feather-icon
                  :icon="isCopied('sales_link') ? 'CheckIcon' : 'CopyIcon'"
                />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </li>
        <li>
          <label>
            {{ $t('cards.affiliateView.reports_link') }}
          </label>
          <b-input-group>
            <b-form-input
              :value="itemData.reports_link"
              :readonly="true"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                @click="copyAction('reports_link')"
                @mouseleave="isCopiedFlags.reports_link = false"
              >
                <feather-icon
                  :icon="isCopied('reports_link') ? 'CheckIcon' : 'CopyIcon'"
                />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </li>
      </ul>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton, BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BInputGroupAppend,
    BFormInput,
    BInputGroup,
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isCopiedFlags: {
        reports_link: false,
        sales_link: false,
      },
    }
  },
  methods: {
    copyAction(attr) {
      this.$copyText(this.itemData[attr])
      this.isCopiedFlags[attr] = true
      setTimeout(() => {
        this.isCopiedFlags[attr] = false
      }, 2000)
    },
    isCopied(attr) {
      return this.isCopiedFlags[attr] || false
    },
  },
}
</script>

<style>

</style>
