<template>
  <div>

    <!-- Alert: No item found -->
    <!--    <b-alert-->
    <!--      variant="danger"-->
    <!--      :show="itemData === undefined"-->
    <!--    >-->
    <!--      <h4 class="alert-heading">-->
    <!--        Error fetching affiliate data-->
    <!--      </h4>-->
    <!--      <div class="alert-body">-->
    <!--        No affiliate found with this affiliate id. Check-->
    <!--        <b-link-->
    <!--          class="alert-link"-->
    <!--          :to="{ name: 'affiliates.list'}"-->
    <!--        >-->
    <!--          Affiliates List-->
    <!--        </b-link>-->
    <!--        for other affiliates.-->
    <!--      </div>-->
    <!--    </b-alert>-->

    <affiliate-details-loader
      v-if="isLoading"
    />
    <template v-else-if="itemData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <affiliate-info-card
            :item-data="itemData"
            :meta-data="affiliateMetaData"
            @edit="isSidebarActive=true"
          />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <affiliate-links-card
            :item-data="itemData"
          />
        </b-col>
      </b-row>

      <!--      <b-row>-->
      <!--        <b-col-->
      <!--          cols="12"-->
      <!--          lg="6"-->
      <!--        >-->
      <!--          <affiliate-timeline-card />-->
      <!--        </b-col>-->
      <!--        <b-col-->
      <!--          cols="12"-->
      <!--          lg="6"-->
      <!--        >-->
      <!--          <affiliate-permissions-card />-->
      <!--        </b-col>-->
      <!--      </b-row>-->

      <!--      <invoice-list />-->

      <!-- Filters -->
      <filters
        :order-status-filter.sync="orderStatusFilter"
        :commission-status-filter.sync="commissionStatusFilter"
        :from-date-filter.sync="fromDateFilter"
        :to-date-filter.sync="toDateFilter"
      />
      <model-list-table
        :store-module-name="storeModuleName"
        :table-columns="tableColumns"
        :filters-list="filtersList"
        :query-params="getQueryParams"
        :add-action="false"
        :view-action="true"
        :view-action-label="$t('modules.affiliates.actions.view_order')"
        view-action-route="orders.view"
        id-key="order_id"
        :edit-action="false"
        :delete-action="false"
        :extra-actions="[]"
        :is-sortable="false"
        :is-searchable="false"
        @update:meta="newMeta => commissionsMetaData = newMeta"
      >
        <template #filters>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <b-avatar
                variant="light-info"
                rounded
              >
                <feather-icon
                  icon="ShoppingCartIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ commissionsMetaData.commissions_count || 0 }}
                </h5>
                <small>{{ $t(`cards.affiliateView.orders_count`) }}</small>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <b-avatar
                variant="light-primary"
                rounded
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ $n((commissionsMetaData.commissions_sum || 0) / 100, 'currency') }}
                </h5>
                <small>{{ $t(`cards.affiliateView.commission_total`) }}</small>
              </div>
            </div>
          </div>
        </template>
      </model-list-table>

      <sidebar-add-new-affiliate
        :is-sidebar-active.sync="isSidebarActive"
        :item-id="itemData.id"
        :value="itemData"
        @refetch-data="fetchAffiliate"
      />
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from 'vue'
import {
  BRow, BCol, BAvatar,
} from 'bootstrap-vue'
import affiliateStoreModule from '@/views/models/affiliates/affiliateStoreModule'
import affiliateCommissionStoreModule from '@/views/models/affiliates/affiliateCommissionStoreModule'
import { formatDatetime, priceText } from '@core/utils/filter'
import ModelListTable from '@/views/models/common/ModelListTable.vue'
import AffiliateDetailsLoader from '@/views/loaders/AffiliateDetailsLoader.vue'
import SidebarAddNewAffiliate from '@/views/models/affiliates/add/SidebarAddNewAffiliate.vue'
import AffiliateLinksCard from '@/views/models/affiliates/view/AffiliateLinksCard.vue'
import Filters from '@/views/models/affiliates/list/Filters.vue'
import AffiliateInfoCard from './AffiliateInfoCard.vue'

export default {
  components: {
    BAvatar,
    Filters,
    AffiliateLinksCard,
    BRow,
    BCol,

    AffiliateDetailsLoader,
    // Local Components
    AffiliateInfoCard,
    SidebarAddNewAffiliate,
    // AffiliateTimelineCard,
    // AffiliatePermissionsCard,

    // InvoiceList,
    ModelListTable,
  },
  setup() {
    const itemData = ref({
      id: null,
    })
    const isLoading = ref(true)
    const affiliateMetaData = ref({})

    const STORE_MODULE_NAME = 'affiliates'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, affiliateStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const fetchAffiliate = () => {
      isLoading.value = true
      store.dispatch('affiliates/fetchOneWithMeta', router.currentRoute.params.id)
        .then(response => {
          itemData.value = response.data
          affiliateMetaData.value = response.meta
        })
        .catch(error => {
          if (error.response.status === 404) {
            itemData.value = null
            router.push({ name: 'affiliates.list' })
          }
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    fetchAffiliate()

    const AFFILIATE_COMMISSIONS_STORE_MODULE_NAME = 'affiliate_commissions'

    // Register module
    if (!store.hasModule(AFFILIATE_COMMISSIONS_STORE_MODULE_NAME)) store.registerModule(AFFILIATE_COMMISSIONS_STORE_MODULE_NAME, affiliateCommissionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AFFILIATE_COMMISSIONS_STORE_MODULE_NAME)) store.unregisterModule(AFFILIATE_COMMISSIONS_STORE_MODULE_NAME)
    })

    const tableColumns = [
      { key: 'id', sortable: false, label: 'ID' },
      {
        key: 'order_short_id', sortable: true, label: 'Order',
      },
      { key: 'order_status', sortable: true, label: 'Order Status' },
      {
        key: 'commission_amount', sortable: true, label: 'Commission Amount', formatter: priceText,
      },
      { key: 'commission_status', sortable: true, label: 'Commission Status' },
      {
        key: 'created_at',
        label: 'Creation Date',
        formatter: formatDatetime,
        sortable: true,
      },
      {
        key: 'updated_at',
        label: 'Last Update',
        formatter: formatDatetime,
        sortable: true,
      },
      { key: 'actions' },
    ]

    const commissionsMetaData = ref({})
    const orderStatusFilter = ref(null)
    const commissionStatusFilter = ref(null)
    const fromDateFilter = ref(null)
    const toDateFilter = ref(null)
    const filtersList = [orderStatusFilter, commissionStatusFilter, fromDateFilter, toDateFilter]

    const isSidebarActive = ref(false)

    const getQueryParams = () => ({
      affiliate_id: router.currentRoute.params.id,
      order_status: orderStatusFilter.value,
      status: commissionStatusFilter.value,
      from_date: fromDateFilter.value,
      to_date: toDateFilter.value,
    })
    return {
      isLoading,
      itemData,
      affiliateMetaData,
      commissionsMetaData,
      fetchAffiliate,

      // Table props
      tableColumns,
      storeModuleName: AFFILIATE_COMMISSIONS_STORE_MODULE_NAME,
      filtersList,
      orderStatusFilter,
      commissionStatusFilter,
      fromDateFilter,
      toDateFilter,
      getQueryParams,

      isSidebarActive,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
