<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="null /* For future use */"
            :text="avatarText(itemData.name)"
            :variant="`light-primary`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ itemData.name }}
              </h4>
              <span class="card-text">
                {{ itemData.email }}
                <b-badge
                  v-if="itemData.email"
                  pill
                  :variant="`light-` + resolveBooleanStatusVariant(itemData.is_email_verified)"
                >
                  {{ itemData.is_email_verified ? $t(`Verified`) : $t(`Unverified`) }}
                </b-badge>
              </span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                v-t="'Edit'"
                variant="primary"
                @click="$emit('edit')"
              />
              <!--              <b-button-->
              <!--                variant="outline-danger"-->
              <!--                class="ml-1"-->
              <!--              >-->
              <!--                Delete-->
              <!--              </b-button>-->
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-info"
              rounded
            >
              <feather-icon
                icon="ShoppingCartIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ metaData.commissions_count || 0 }}
              </h5>
              <small>{{ $t(`cards.affiliateView.orders_count`) }}</small>
            </div>
          </div>
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-secondary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ $n((metaData.commissions_sum_pending || 0) / 100, 'currency') }}
              </h5>
              <small>{{ $t(`cards.affiliateView.commissions_sum_pending`) }}</small>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ $n((metaData.commissions_sum_approved || 0) / 100, 'currency') }}
              </h5>
              <small>{{ $t(`cards.affiliateView.commissions_sum_approved`) }}</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="HashIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">ID</span>
            </th>
            <td class="pb-50">
              {{ itemData.id }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span
                v-t="`inputs.status`"
                class="font-weight-bold"
              />
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge
                pill
                :variant="`light-` + resolveBooleanImportantStatusVariant(itemData.is_active)"
              >
                {{ itemData.is_active ? $t(`Active`) : $t(`Inactive`) }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span
                v-t="`inputs.country`"
                class="font-weight-bold"
              />
            </th>
            <td class="pb-50">
              {{ itemData.country_code || '-' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span
                v-t="`inputs.mobile`"
                class="font-weight-bold"
              />
            </th>
            <td>
              <span style="direction: ltr"><span class="d-inline-block">{{ itemData.mobile ? itemData.formatted_mobile : '-' }}</span></span>
              <b-badge
                v-if="itemData.mobile"
                pill
                :variant="`light-` + resolveBooleanStatusVariant(itemData.is_mobile_verified)"
              >
                {{ itemData.is_mobile_verified ? $t(`Verified`) : $t(`Unverified`) }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span
                v-t="`inputs.registration_date`"
                class="font-weight-bold"
              />
            </th>
            <td>
              {{ formatDate(itemData.created_at) }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import { avatarText, formatDate } from '@core/utils/filter'
import useModelList from '@/views/models/common/useModelList'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge,
  },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
    metaData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const {
      resolveBooleanStatusVariant,
      resolveBooleanImportantStatusVariant,
    } = useModelList('affiliates', [])

    return {
      avatarText,
      resolveBooleanStatusVariant,
      resolveBooleanImportantStatusVariant,
      formatDate,
    }
  },
}
</script>

<style>

</style>
