import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import affiliateModel from '@/views/models/affiliates/affiliateModel'
import affiliateCommissionModel from '@/views/models/affiliates/affiliateCommissionModel'

const {
  fetchAll,
  fetchOne,
  update,
} = useModelStoreModule(affiliateCommissionModel)

const {
  fetchAllChildren,
} = useModelStoreModule(affiliateModel)

const fetchCommissions = (ctx, params) => fetchAllChildren(ctx, params.affiliate_id, 'commissions', params)
// TODO: Find best way to list commissions in AffiliateView
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAll: fetchCommissions,
    fetchOne,
    update,
  },
}
