<script>
import { ContentLoader } from 'vue-content-loader'
import { computed } from 'vue'

export default {
  components: { ContentLoader },
  setup() {
    const isDarkSkin = computed(ctx => ctx.$store.state.appConfig.layout.skin === 'dark')
    const primaryColor = computed(() => (isDarkSkin.value ? '#283046' : '#eaeced'))
    const secondaryColor = computed(() => (isDarkSkin.value ? '#435178' : '#ffffff'))

    return {
      primaryColor,
      secondaryColor,
    }
  },
}
</script>

<template>
  <content-loader
    width="900"
    height="900"
    :view-box="`0 0 900 900`"
    :speed="2"
    :primary-color="primaryColor"
    :secondary-color="secondaryColor"
  >
    <rect x="20" y="20" rx="0" ry="0" width="880" height="150" />
<!--    <rect x="20" y="190" rx="0" ry="0" width="430" height="200" />-->
<!--    <rect x="470" y="190" rx="0" ry="0" width="430" height="200" />-->
    <rect x="20" y="190" rx="0" ry="0" width="880" height="200" />
  </content-loader>
</template>
